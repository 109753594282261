<template>
    <div class="font-stolzl">
        <div class="min-h-screen mb-10">
            
            <!-- SPACE-->
            <div id="space" class="md:h-16"></div>
            <!-- SPACE-->
            
            
            <!-- HEADING-->
            <div  class="w-auto h-auto overflow-hidden">
              <div :ref="setRef" data-anim="0" class="break-words font-light text-coolGray-900 mt-5 md:mt-28 mb-5 text-5xl px-4 md:px-8 opacity-0">Allgemeine Geschäftsbedingungen</div>
            </div>
            <!-- HEADING-->
            
            <!-- CONTENT-->
            <pre class="font-stolzl w-full md:w-3/4 px-4 md:px-8 font-light text-base mt-5 text-coolGray-900 whitespace-pre-line">
            § 1
            ZUSTANDEKOMMEN DES VERTRAGS
            
            Die Darstellung der Waren in unserem Onlineshop ist kein rechtlich wirksames Angebot, sondern der Kundegibt durch seine Online-Bestellung ein verbindliches Kaufangebot ab. Wir nehmen das Kaufangebot durchausdrückliche Auftragsbestätigung per E-Mail oder durch Lieferung der bestellten Ware an. Eine E-Mail, mit der wir nur den Eingang der Bestellung bestätigen, ist noch keine verbindliche Annahme des Kaufangebots.
            
            
            § 2
            ZAHLUNG
            
            1. Unsere Forderungen sind mit Ablieferung der Ware beim Kunden fällig und zahlbar ohne Abzug in EURO per Kreditkarte, PayPal, Rechnung, Lastschrift, Sofortüberweisung oder Vorkasse.
            
            2. Dem Kunden steht das Recht zur Aufrechnung nur zu, wenn sein Gegenanspruch rechtskräftig festgestellt oder unbestritten ist. Dieses Aufrechnungsverbot gilt nicht für einen Gegenanspruch wegen eines Mangels, der auf demselben Vertragsverhältnis wie unsere Forderung beruht. Zur Ausübung eines Zurückbehaltungsrechts ist der Kunde nur insoweit befugt, als sein Gegenanspruch auf dem selben Vertragsverhältnis beruht.
            
            
            § 3
            ANSPRÜCHE BEI MÄNGELN
            
            Ist der Kunde ein Verbraucher, hat er bei einem Mangel die gesetzlichen Ansprüche.
            
            Ist der Kunde kein Verbraucher, gilt ergänzend:
            Die Nacherfüllung erfolgt nach unserer Wahl durch Beseitigung des Mangels oder Neulieferung.
            
            
            § 4
            HAFTUNG AUF SCHADENS- UND AUFWENDUNGSERSATZ
            
            Wir haften unbeschränkt bei Vorsatz, grober Fahrlässigkeit, beim Fehlen einer garantierten Beschaffenheit, für Personenschäden und nach dem Produkthaftungsgesetz.
            
            Bei leicht fahrlässiger Verletzung vertragswesentlicher Pflichten (insbesondere die Pflicht zur rechtzeitigen und mangelfreien Lieferung) ist unsere Haftung beschränkt auf den vertragstypischen, vorhersehbaren Schaden.
            
            Unsere Haftung für die leicht fahrlässige Verletzung nicht vertragswesentlicher Pflichten ist ausgeschlossen.
            
            Die vorstehenden Regelungen gelten für unsere Haftung auf Ersatz vergeblicher Aufwendungen entsprechend.
            
            
            § 5
            VERJÄHRUNG VON MÄNGEL- UND ERSATZANSPRÜCHEN
            
            Ist der Kunde ein Verbraucher, gelten die gesetzlichen Regelungen.
            
            Ist der Kunde kein Verbraucher, gilt:
            Die Verjährungsfrist für Ansprüche des Kunden wegen eines Mangels beträgt ein Jahr. Dies gilt nicht, soweit zwingend längere Fristen vorgeschrieben sind. Dies gilt ebenfalls nicht für Schadens- und Aufwendungsersatzansprüche, die auf Ersatz eines Körper- oder Gesundheitsschadens gerichtet oder auf Vorsatz oder grobe Fahrlässigkeit gestützt sind.
            
            
            § 6
            SCHLUSSBESTIMMUNGEN
            
            1. Sollte eine Bestimmung dieser Bedingungen unwirksam sein oder werden, so wird die Wirksamkeit der übrigen Bestimmungen hierdurch nicht berührt.
            
            2. Wir sind zur Teilnahme an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nicht verpflichtet und bereit.
            </pre>
            <!-- CONTENT-->
            
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '../components/footer.vue';
import scrollToTop from '../mixins/scrollToTop.vue';
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'AGB',
  components: { Footer },
  mixins: [ scrollToTop, intersectionObserver ],
}
</script>